
import { defineComponent } from "vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "policy-table-dropdown",
  components: {},
  props: {
    publicId: String,
    code: String,
    canBeRenewed: Boolean,
    hasPolicyDocument: Boolean,
  },
  data() {
    return {
      link: "",
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      removeLastHyphen,
    } = ResusableFunctions();

    const renewalRoute = (value, publicId) => {
      let route = "";

      switch (value) {
        case ["HOM-HOU-OWN", "HOM-HOU", "HOM-HOU-OWN-", "HOM-HOU-"].includes(
          value
        ):
          route = `/policy/renewal/house-insurance/owned-building/${publicId}`;
          break;
        case "HOM-HOU-TEN":
          route = `/policy/renewal/house-insurance/rented-building/${publicId}`;
          break;
        case "HOM-HOU-HPA":
          route = `/policy/renewal/house-insurance/personal-accident/${publicId}`;
          break;
        case "HOM-HOU-CON":
          route = `/policy/renewal/house-insurance/contents/${publicId}`;
          break;
        case "MOT":
          route = `/policy/renewal/motor-insurance/${publicId}`;
          break;
        default:
          route = "";
          break;
      }

      return route;
    };

    return {
      reinitialization,
      useReusableNavigation,
      removeLastHyphen,
      renewalRoute,
    };
  },
});
